require("lightgallery.js/dist/js/lightgallery.js");
export default class Artwork {
  init() {
    let imagePlaceholder = document.querySelector("#defaultImage");
    let images = document.querySelectorAll(".onClickImage");
    let imageLightbox = document.querySelector("#zoomImageLightbox");
    let zoomButton = document.querySelector("#zoom-btn-container");
    images.forEach((image) => {
      image.addEventListener("click", () => {
        images.forEach((image) => {
          image.style.border = "0 none";
        });
        imagePlaceholder.src = image.src;
        zoomButton.href = image.src;
        image.style.border = "2px solid #AE6C41";
      });
    });
    let controlElement = "";
    let methodsInstance;
    const elements = document.querySelectorAll(".pt-gallery");
    controlElement =
      '<svg class="SvgIcon" aria-hidden="true" role="presentation" shape-rendering="geometricPrecision" style="--svg-size: 48px"><use class="icon-chevron-left-icon" xlink:href="https://www.efa-kilkis.gr/wp-content/themes/toolstrap/public/images/icons.svg#icon-chevron-left-icon"></use></svg>';
    elements.forEach((gallery) => {
      lightGallery(gallery, {
        selector: ".zoomImage",
        counter: false,
        download: false,
        prevHtml: controlElement,
        nextHtml: controlElement,
      });
    });
    const btn = document.querySelector("#download-btn");
    if (btn) {
      btn.addEventListener("click", (event) => {
        const url = imagePlaceholder.getAttribute("src");
        event.preventDefault();
        console.log("ABC");
        downloadImage(url);
      });
    }
    function downloadImage(url) {
      fetch(url, {
        mode: "no-cors",
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.download = url.replace(/^.*[\\\/]/, "");
          a.href = blobUrl;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    }
  }
}
