import Global from './global';

const global = new Global();

document.addEventListener('DOMContentLoaded', () => {
  global.initOnDOMContentLoaded();
});

window.addEventListener('load', () => {
  global.initOnWindowload();
  
  function getCurrentURL () {
    return window.location.href
  }
  
  // Example
  const url = getCurrentURL()
});
